$font-size: 16pt;
$line-height: 24pt;
$indent: 24pt;

$background-color: #f0f0f0;
$text-color: #333;
$header-color: #666;
$highlight-color: #ff0;
$footer-color: #999;

html {
    margin: 0 $line-height;

    font-family: "Avenir Next", Avenir, Helvetica, sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;

    background-color: $background-color;
    color: $text-color;
}

body {
    margin-top: $line-height*2;
    margin-bottom: $line-height*4;
    margin-left: auto;
    margin-right: auto;

    max-width: 460pt;
}

h1 {
    overflow: hidden;

    margin: 0;
    margin-top: $line-height*2;
    margin-bottom: $font-size*2;

    text-align: center;

    font-size: $font-size*2;
    font-weight: bold;
    line-height: $line-height*2;

    a {
        text-decoration: none;
        color: $text-color;
    }
}

h2 {
    text-align: center;
    font-weight: normal;
}

em {
    background-color: $highlight-color;
    font-style: normal;
}

h3 {
    margin: 0;
    margin-top: $line-height*2;
    margin-bottom: $font-size;

    text-align: left;
    color: $header-color;

    font-size: $font-size;
    font-weight: 600;
    line-height: $line-height;
}

h2, p {
    margin-top: 0;
    margin-bottom: $font-size;
    margin-left: auto;
    margin-right: auto;

    font-size: $font-size;
    line-height: $line-height;
}

p {
    text-align: justify;
    text-justify: newspaper;
    -webkit-hyphens: manual;
    hyphens: manual;
}

@import "san-francisco.css";

code {
    font-family: "SF Mono", Courier, monospace;
    font-size: $font-size * 0.87;
}

pre {
    margin-bottom: $font-size;
    white-space: pre-wrap;
}

pre, blockquote {
    padding-left: $indent - 6pt;
    margin-left: 0;
    margin-right: 0;
    border-left: 6pt solid #ccc;
}

pre > code {
    font-size: $font-size * 0.75;
    tab-size: 4;
}


a {
    color: #009;
}

ul, ol {
    font-size: $font-size;
}

img {
    display: block;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;

    background: white;
    border: 1px solid gray;

    max-width: 100%;
}

img.profile {
    width: 14em;
}

.pixelated {
    image-rendering: pixelated;
}

#navigation {
    text-align: center;

    margin-top: $line-height*2;

    a {
        display: inline-block;
    }

    .next {
        text-align: right;
        float: right;
        margin-bottom: $line-height*2;
    }
    .next:after {
        content: "\00a0\2192";
    }

    .prev {
        text-align: left;
        float: left;
        margin-bottom: $line-height*2;
    }
    .prev:before {
        content: "\2190\00a0";
    }

    clear: both;
}

#footer {
    clear: both;
    margin-top: $line-height*2;
    text-align: center;
    color: $footer-color;
    a {
        color: $footer-color;
    }
}

@media (max-width: 375pt) {
    html {
        margin: 12pt;
    }

    h1 {
        font-size: 20pt;
    }

    h2, p {
        font-size: 12pt;
        margin-bottom: 12pt;
        line-height: 20pt;
    }

    ul, ol {
        font-size: 12pt;
        margin-bottom: 12pt;
        line-height: 20pt;
    }

    code {
        font-size: 12pt * 0.87;
    }

    pre > code {
        font-size: 9pt;
        tab-size: 3;
    }

    pre, blockquote {
        padding-left: 6pt;
        margin-left: 0;
        margin-right: 0;
        border-left: 3pt solid #ccc;
    }

    h2 {
        margin-top: 20pt;
    }
}
